/* This file will hold styles for the mobile version of your website (mobile first). */
/* This also can include ANY global CSS that applies site-wide. Unless overwritten by a more specific style rule, CSS declarations in global.css will apply site-wide. */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700);
html{
	background:#f2f2f2;
		background-image: url(img/bg.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	background-attachment: fixed;
	font-family: 'Open Sans', sans-serif;

}

body {
	background-repeat: no-repeat;
	background-position: center top;
	font-size:16px;
}
.front {
	/*background-image: url(img/imgHeader.jpg);*/
}





p {
	font-size:1em;
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
	line-height:23px;
	clear: both;
	line-height:1.6em;
}
label {
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
}
.node ul {
	margin:0px;
	padding:0px;
}

.node ul li, .views-field-body ul li {
	font-size: 0.95em;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	line-height: 23px;
	border-bottom: 1px dotted #ccc;
	padding-bottom: 10px;
	margin-bottom: 10px;
	list-style-type:salpha;
	list-style-position: inside;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
	font-family: 'Open Sans', sans-serif;
}
h1 {
	font-weight: 300;
	font-size: 3em;
}
h2 {
	font-weight: 300;
	font-size: 2.8em;
}

h3 {
	font-weight: 300;
	font-size: 2.5em;
}
h4 {
	font-weight: 300;
	font-size: 2.5em;
}
h5 {
	font-weight: 300;
	font-size: 1.7em;
	color: #cd7642;
	letter-spacing: 0.1px;
}
#section-header,
#section-content,
#section-footer {
	background-image: url(img/bg_blanc.png);
	background-repeat: repeat-y;
	padding-top:20px;
	background-position: center center;


	/*background:url(img/pattern.png) repeat;*/
}
#zone-header-wrapper{
	width: 1230px;
	background: #fff;
	margin: 0px auto;
	padding-top: 20px;
	padding-bottom: 10px;
	max-width: 1230px;
	width: 100%;
	overflow: hidden;
}
#region-ban-head {
	width: 100%;
	//background: #00622b;
	margin:0px auto;
	//max-width:1230px;
}
#region-ban-head  {

}
#zone-ban-headzones {
	background: #00622b;
	margin: 0px auto;
	overflow: hidden;

	@media(min-width: 740px){
		width: 100%;
		max-width: 1230px;
	}
}

@media(min-width:740px){
	.page-node-4 #zone-ban-headzones,
	.page-node-1 #zone-ban-headzones{
		//height:350px;
	}
}



.page-node-15 #zone-ban-headzones{
	height: auto;
	background-color: white;
	#region-ban-head{
		background-color: white;
	}

}

.page-node-15 .field-name-body{
	a.colorbox {
		&:nth-child(odd){
		 img{
			 float: left;
		 }
		}
		&:nth-child(even){
		 img{
			 float: right;
		 }
		}
		img{
			float: left;
			width: 200px;
			height: auto;
			margin-right: 5px;
			margin-bottom: 5px;

		}
	}
}



.front #section-header {
	/*height: 719px;*/

}
/*MENU */
#block-system-main-menu{
	width:100%;
	    margin-top: 14px;
}


#block-system-main-menu ul {
	text-align:right;
	position: relative;
}

#block-system-main-menu ul li{
	display:inline-block;
	margin-top: 0;
	margin-left:0;
		padding: 0;
		@media (min-width: 740px){
			margin-top:16px;
		}
}

#block-system-main-menu ul li a{
	font-family: 'Open Sans', sans-serif;
    font-size: .9em;
	font-weight:300;
	color: white;
	text-decoration:none;
	padding:6px 7px 6px 4px;
	letter-spacing:0.01em;
	@media (min-width: 740px){
		color:#00622b;
	}
}
#block-system-main-menu ul li a:hover,
#block-system-main-menu ul li .active{
	background-color:#00622b;
	color:white;

	@media (min-width: 740px){
		-webkit-border-radius: 10px;
		-moz-border-radius: 10x;
		border-radius: 10px;
	}


}

/*MENU */

/*BLOCK HEADER*/
#zone-header2{
	font-family: 'Open Sans', sans-serif;
	color:white;
}
#block-block-2 .imgfibre{
float:left;
margin-right:15px;
}
#slogan-accueil{
	font-family: 'Open Sans', sans-serif;
	display:block;
	color:white;
	font-size: 2.5em;
	font-weight:300;
	line-height:70px;
}

#zone-header2 h3{
	font-family: 'Open Sans', sans-serif;
	font-size:2.375em;
	font-weight:300;
	border-bottom:1px #5c7d55 solid;

}
#zone-header2 p{
	font-size:1.1875em;
	font-weight:300;
	margin-top:5px;
}




#region-imgheader{
	text-align:center;
}
/*BLOCK HEADER*/

/*CONTENU ACCUEIL*/
/*TERREAUX*/
.block-block-7{
background-color:#33231c;
color:white;
}

/*TERREAUX*/

#region-terreau1,
#region-terreau2,
#region-terreau3,
#region-pallis1,
#region-pallis2,
#region-pallis3,
#region-composte1,
#region-composte2,
#region-composte3,
#region-fibre1,
#region-fibre2,
#region-fibre3{
	overflow: hidden;
	.content img{
		width: 100%;
	}
}


#region-terreau2,
#region-pallis1,
#region-composte3,
#region-fibre1{
	//width: 100%;
	//	margin-left: 0;
}

@media (min-width:980px){

	#region-fibre1,
	#region-terreau2,
	#region-pallis1,
	#region-composte3{
		width: 300px;
		//margin-left: auto;
	}
#region-terreau1{
	//margin-left:0;
}
#region-composte1{
//	margin-left:0;
}
#region-terreau2{
	margin-left: 10px;
}


}



/* pour mobile*/
#region-terreau1,
#region-terreau3,
#region-pallis2,
#region-pallis3,
#region-composte1,
#region-composte2,
#region-fibre2,
#region-fibre3{
	display: none;
}
@media (min-width:980px){
	#region-terreau1,
	#region-terreau3,
	#region-pallis2,
	#region-pallis3,
	#region-composte1,
	#region-composte2,
	#region-fibre2,
	#region-fibre3{
		display: block;
		//width: auto;
	}
}


#region-terreau1, #region-terreau2, #region-terreau3 {
	background:#33231c;
	height:230px;
	margin-bottom:20px;
}
/*PAILLIS*/
#region-pallis1, #region-pallis2, #region-pallis3 {
	background:#d75d47;
	height:230px;
	margin-bottom:20px;
}
/*PAILLIS*/
#region-composte1, #region-composte2, #region-composte3 {
	background:#1d1c1d;
	height:230px;
	margin-bottom: 20px;
}


#region-fibre1, #region-fibre2, #region-fibre3 {
	background:#d75d47;
	height:230px;
	margin-bottom:20px;
}




/*COMPOSTE*/



#region-terreau2 .region-inner .content a,
#region-pallis1 .region-inner .content a,
#region-composte3 .region-inner .content a,
#region-fibre1 .region-inner .content a{
	font-family: 'Open Sans', sans-serif;
	font-size: 1em;
	font-weight: 300;
	color:#fff;
	text-indent:-9999px;
	width:14px;
	display:block;
	height:24px;
	background:url(img/fleche.png);
	position:absolute;
	bottom:0px;
	right:0px;
	opacity:1;
}
#region-terreau2 .region-inner .content a:hover, #region-pallis1 .region-inner .content a:hover, #region-composte3 .region-inner .content a:hover {
	opacity:0.5;
}
#region-terreau2 .region-inner,
#region-pallis1 .region-inner,
#region-composte3 .region-inner,
#region-fibre1 .region-inner{
	padding:20px;
}
#region-terreau2 .region-inner p,
#region-pallis1 .region-inner p,
#region-composte3 .region-inner p,
#region-fibre1 .region-inner p {
font-family: 'Open Sans', sans-serif;
font-size: 1em;
font-weight: 300;
color:#fff;
}
#region-terreau2 h2,
#region-pallis1 h2,
#region-composte3 h2,
#region-fibre1 h2{
	font-family: 'Open Sans', sans-serif;
	font-size: 3.5em;
	font-weight: 300;
	margin: 0px;
	line-height: 30px;
	margin-top: 15px;
	color:#fff;
}
#region-terreau2 h3{
	font-family: 'Open Sans', sans-serif;
	font-size: 1.8em;
	margin: 14px 0 0 0px;
	font-weight: 500;
	letter-spacing: 0.2px;
	text-transform: uppercase;
	color: white;
	line-height: 27px;
}

#region-pallis1 h3,
#region-composte3 h3,
#region-fibre1 h3 {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.8em;
	margin: 0px;
	margin-top: 5px;
	font-weight: 500;
	letter-spacing: 0.2px;
	text-transform: uppercase;
	color:#fff;
}
#region-composte3 h3{
	font-size: 1.6em;
}

#block-views-produit-block {
	border-top: 2px solid #00622b;
	margin-top: 30px;
	padding-top: 30px;
}

#block-views-produit-block-1 .group {
	width: 100%;
	@media (min-width: 740px){
		width: 40%;
		float: left;
	}

}

#block-views-produit-block-1 .group a{
	border-bottom: 1px dotted #ccc;
	padding-bottom: 5px;
	display: block;
	margin-bottom: 5px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 1em;
}

#block-views-produit-block-1 .group:nth-child(2) {
margin-left:2%;
margin-right:2%;
}
#region-content-front {
margin-top:30px;
}


#region-content-front .views-field-body {
	float:left;
	width:100%;
	margin-right:4%;
	@media (min-width:740px){
	width:50%;
	}
}





#region-content-front .views-field-field-photos {
	text-align: center;
	@media (min-width:740px){
		float:left;
		width:46%;
	}
}
#region-content-front .views-field-field-photos img {
	max-width: 100%;
	@media (min-width:740px){
		width:100% !important;
        height: auto;
        margin-top: 31px;
	}
}

@media (min-width:740px){

}


/* FOOTER */
#block-footer-sitemap-footer-sitemap {
	border-top:1px solid #c9c9c9;
	padding-top:20px;
	margin-top:20px;
}
#block-footer-sitemap-footer-sitemap ul li a {
	font-size: 0.95em;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
text-decoration:none;
color:#00622b;
margin-right:20px;
padding: 5px 10px 5px 10px;
}
#block-footer-sitemap-footer-sitemap ul .active a {
background-color: #00622b;
color: white;
-webkit-border-radius: 40px;
-moz-border-radius: 40x;
border-radius:40px;
}
#footer-sitemap {
margin:0px;
}
#block-block-15 {
	margin-top:15px;
	font-size:14px;
}
#block-block-15 a{
	color:black;
	text-decoration:none;
}
#block-block-15 a:hover{
	text-decoration:underline;
}
#block-block-15 a img{
	margin: 0 0 3px 6px;
}

#block-views-fibre-de-bois-block .views-row  {
	width:32%;
	float:left;
}
#block-views-fibre-de-bois-block .views-row-2  {
	width:32%;
	float:left;
	margin-left:2%;
	margin-right:2%;
}
#block-views-fibre-de-bois-block h2 {
	background: url(http://produitshorticoleslavoie.com/sites/all/themes/yveslavoie/css/img/fibre-bois.png) left center no-repeat;

	padding-left: 100px;
	font-size: 2.75em;
	line-height: 70px;
	@media (min-width: 740px){
		height: 78px;
	}
}
#block-views-fibre-de-bois-block h2 a{
color:#fff;
text-decoration:none;
}

#block-views-fibre-de-bois-block .views-field-nothing p {
display:inline;

}
#block-views-fibre-de-bois-block .views-field-nothing a {
	color: #fff;
text-decoration: none;
font-family: 'Open Sans', sans-serif;
font-style: italic;
	display: inline-block;
}
#block-views-fibre-de-bois-block .views-field-nothing a:hover {
	text-decoration:underline;
}

/* FIBRE DE BOIS*/


.page-node-4 h1 {

	@media (min-width: 740px){
		height: 78px;
		line-height: 70px;
	}
}
.page-node-4 H1:after {
 border-top: 1px solid black;
    display: block;
    height: 1px;
	width: 40%;
}

.field-name-field-paragraphe{
	.field-item{
		width: 100%;
	}
	.field-name-field-icon {
		float: left;
		margin-top: 10px;
		img{
			width: auto;
			height: 43px;
		//	margin-top: 10px;
			margin-right: 10px;
			@media (min-width: 740px){
				width: 100%;
				height: auto;
			}

		}
		@media (min-width: 740px){
			display: block;
			width: 20%;
			float: left;
			padding-top: 7px;
			margin-right: 10px;
		}
	}
	h3{
		@media (min-width: 740px){
			padding-top: 16px;
		}
		@media (min-width: 980px){
			padding-top: 26px;
		}


	}

}


.field-name-field-paragraphe .even  {
	float:left;
	width: 100%;
	@media (min-width: 740px){
		width: 48%;
		margin-right:2%;
	}
}
.field-name-field-paragraphe .odd  {
	float:left;
	width: 100%;
	@media (min-width: 740px){
		float:right;
		width: 48%;
		margin-left:2%;
	}
}



.field-name-field-paragraphe .group_text {
	width:78%;
	float:left;
	margin-left:2%;

}


.field-name-field-paragraphe .content .even {
	float:none;
	width: 100%;
}


table {
width:100% !important;
font-size: 0.95em;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
line-height: 23px;
}
tr {
border: 1px dashed #ccc;
}
td {
text-align:center;
border: 1px dashed #ccc;
}

/* PRODUIT */
#block-views-produit-block h3{
	clear:both;
}
#block-views-produit-block h2 {
	font-size: 1em;
font-weight: 400;
letter-spacing: .1px;
font-style: italic;
font-family: arial;
}
#block-views-produit-block .views-row {
	overflow:hidden;
	min-height:220px;
	margin-bottom:15px;
	padding-bottom:15px;
	border-bottom:1px dotted #ccc;
}
#block-views-produit-block .views-row-even {
}
#block-views-produit-block  h3{
font-style: italic;
font-size: 3em;
font-weight: 300;
}
#block-views-produit-block  h3 a{
	color:#00622b;
	text-decoration:none;
	pointer-events: none;
}
#block-views-produit-block  h3 a:hover{
	color:#00622b;
	text-decoration:none;
}
#block-views-produit-block  h4{
	margin:0px;
}

#block-views-produit-block .views-field-body {
	.quebecvrai{
		float: right;
		margin-left: 18px;
		/* display: block; */
		/* max-width: 100%; */
		width: 128px;
	}

	h5{float: left;}
	p, ul{

		clear: left;

	}
@media(min-width: 740px){
	float:right;
	width:70%;
}

}
#block-views-produit-block .views-field-nothing {
	float:right;
	clear:right;
}
#block-views-produit-block .views-field-nothing a.info {
	background: #29a760;
	background-image: -webkit-linear-gradient(top, #29a760, #00622b);
	background-image: -moz-linear-gradient(top, #29a760, #00622b);
	background-image: -ms-linear-gradient(top, #29a760, #00622b);
	background-image: -o-linear-gradient(top, #29a760, #00622b);
	background-image: linear-gradient(to bottom, #29a760, #00622b);
	-webkit-border-radius: 5;
	-moz-border-radius: 5;
	border-radius: 5px;
	font-family: Arial;
	color: #ffffff;
	font-size: 16px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;


	margin:0 !important;
	float:right;
    &:hover{
		background: #00622b;
		background-image: -webkit-linear-gradient(top, #00622b, #00622b);
		background-image: -moz-linear-gradient(top, #00622b, #00622b);
		background-image: -ms-linear-gradient(top, #00622b, #00622b);
		background-image: -o-linear-gradient(top, #00622b, #00622b);
		background-image: linear-gradient(to bottom, #00622b, #00622b);
		text-decoration: none;
    }
}

#region-content .btn-calculateur{
	background: #29a760;
	background-image: -webkit-linear-gradient(top, #29a760, #00622b);
	background-image: -moz-linear-gradient(top, #29a760, #00622b);
	background-image: -ms-linear-gradient(top, #29a760, #00622b);
	background-image: -o-linear-gradient(top, #29a760, #00622b);
	background-image: linear-gradient(to bottom, #29a760, #00622b);
	-webkit-border-radius: 5;
	-moz-border-radius: 5;
	border-radius: 5px;
	font-family: Arial;
	color: #ffffff;
	font-size: 16px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	&:hover{
		background: #00622b;
		background-image: -webkit-linear-gradient(top, #00622b, #00622b);
		background-image: -moz-linear-gradient(top, #00622b, #00622b);
		background-image: -ms-linear-gradient(top, #00622b, #00622b);
		background-image: -o-linear-gradient(top, #00622b, #00622b);
		background-image: linear-gradient(to bottom, #00622b, #00622b);
		text-decoration: none;
	}

}
#block-views-produit-block .views-field-field-photo {
		img{
			display: none;
			max-width: 100%;
			@media(min-width: 740px){
				display: block;
			}
		}



		float:left;
		width: 28%;
   margin-right: 2%;

}


#block-views-produit-block  .views-field-field-galerie{

    clear: right;
    float: right;
    width: 70%;
}
#block-views-produit-block  .views-field-field-galerie li{
	display:inline;
}




#region-content .views-field-edit-node a:link, #region-content .views-field-edit-node a:visited  {
font-size:12px;
text-decoration:none;
font-family:arial;
background-color: #00622b;
color: white;
padding:4px;
}
.colorpaillis li {
font-size: 0.95em;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
}
.colorpaillis .red div {
	width:20px;
	height:20px;
	border-radius:100px;
	background:#900;
	display:block;
	float:left;
	margin-right:10px;
	text-indent:-9999px;
}
.colorpaillis .brown div {
	width:20px;
	height:20px;
	border-radius:100px;
	background:#600;
	display:block;
	float:left;
	margin-right:10px;
	text-indent:-9999px;
}
.colorpaillis .black div {
	width:20px;
	height:20px;
	border-radius:100px;
	background:#000;
	display:block;
	float:left;
	margin-right:10px;
	text-indent:-9999px;
}
.colorpaillis li {
	list-style-type:none;
	float:left;
	width:25%;
}
#views-exposed-form-produit-block {
	margin-bottom: 15px;
	margin-top: 15px;
	padding-top: 15px;
padding-bottom: 15px;
border-top: 1px dotted #ccc;
border-bottom: 1px dotted #ccc;
overflow:hidden;
}

.views-exposed-widget .form-type-select .form-item {
	width: 100%;
	@media(min-width: 740px){
		width:25%;
		float:left;
	}
}
.views-exposed-widget .form-type-select .form-item label {
font-family: 'Open Sans', sans-serif;
font-weight: 400;
font-size: 1em;
}
.views-submit-button {
	width:20% !important;
float:left;
}

.views-exposed-form .views-exposed-widget {
width: 80%;
padding:0 !important;
float:left;
}
.views-exposed-form .views-exposed-widget .form-submit {
	float:right;
	padding:0px;
}
input[type=submit] {
	margin:0 !important;
		-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf) );
	background:-moz-linear-gradient( center top, #ededed 5%, #dfdfdf 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf');
	background-color:#ededed;
	-webkit-border-top-left-radius:6px;
	-moz-border-radius-topleft:6px;
	border-top-left-radius:6px;
	-webkit-border-top-right-radius:6px;
	-moz-border-radius-topright:6px;
	border-top-right-radius:6px;
	-webkit-border-bottom-right-radius:6px;
	-moz-border-radius-bottomright:6px;
	border-bottom-right-radius:6px;
	-webkit-border-bottom-left-radius:6px;
	-moz-border-radius-bottomleft:6px;
	border-bottom-left-radius:6px;
	text-indent:0;
	border:1px solid #dcdcdc;
	display:inline-block;
	color:#777777;
	font-family:arial;
	font-size:15px;
	font-weight:bold;
	font-style:normal;
	height:50px;
	line-height:40px;
	width:120px;
	text-decoration:none;
	text-align:center;
	text-shadow:1px 1px 0px #ffffff;
	text-transform: uppercase;
}
input[type=submit]:hover {
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed) );
	background:-moz-linear-gradient( center top, #dfdfdf 5%, #ededed 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed');
	background-color:#dfdfdf;
}
input[type=submit]:active {
	position:relative;
	top:1px;
}

input[type=text], input[type=email], input[type=password] {
	border:1px solid #ccc;
}
textarea {
	padding:20px;
	height:250px;
	border:1px solid #ccc;
}
input[type=text]:hover, input[type=email]:hover, input[type=password]:hover, textarea:hover {
	box-shadow:0px 0px 0px;
}
input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
	box-shadow:0px 0px 0px;
}

.field-name-field-texte-bottom {
border-top:2px solid #00622b;
margin-top:20px;
padding-top:20px;
}


#region-content .plus_renseignement:link, #region-content .plus_renseignement:visited {
	margin:0 auto;
	display: block;
		-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf) );
	background:-moz-linear-gradient( center top, #ededed 5%, #dfdfdf 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf');
	background-color:#ededed;
	-webkit-border-top-left-radius:6px;
	-moz-border-radius-topleft:6px;
	border-top-left-radius:6px;
	-webkit-border-top-right-radius:6px;
	-moz-border-radius-topright:6px;
	border-top-right-radius:6px;
	-webkit-border-bottom-right-radius:6px;
	-moz-border-radius-bottomright:6px;
	border-bottom-right-radius:6px;
	-webkit-border-bottom-left-radius:6px;
	-moz-border-radius-bottomleft:6px;
	border-bottom-left-radius:6px;
	text-indent:0;
	border:1px solid #dcdcdc;
	margin-top:30px;
	color:#777777;
	font-family:arial;
	font-size:15px;
	font-weight:bold;
	font-style:normal;
	height:50px;
	line-height:50px;
	width:300px;
	text-decoration:none;
	text-align:center;
	text-shadow:1px 1px 0px #ffffff;
	text-transform: uppercase;
}
.plus_renseignement:hover {
	background-color:#007a36;
}
.file {
	//width: 800px;
	margin: 0px auto;
	display: block;
	text-align: center;
}
.file img{
vertical-align:middle;
}
.file a {
	font-size: 0.95em;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
text-decoration: none;
color: #000;
}
.file a:hover {
text-decoration:underline;
}
#region-content a {
	color:#00622b;
	text-decoration:none;
}
#region-content a:hover {
	text-decoration:underline;
}
.bef-checkboxes {
padding-top:10px;
}



.progress-disabled {
  float: none;
  display:inline;
}
.ajax-progress {
 position:fixed;
 top:48%;
 left:49%;

}
.ajax-progress .throbber {
  background: #fff url('img/load.gif') center center  no-repeat ;
  float: none;
  height: 50px;
 -webkit-box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:    0px 0px 20px 0px rgba(50, 50, 50, 0.75);
box-shadow:         0px 0px 20px 0px rgba(50, 50, 50, 0.75);
  width: 50px;
  display:block;
  border-radius:100px;
}
tr .ajax-progress .throbber {
  margin: 0 2px;
}
.ajax-progress-bar {
  width: 16em;
}




/*********************************************/
/* CONTACT */
.page-node-15 .field-name-body {
	@media(min-width:740px){
		width:45%;
		margin-right:5%;
		float:left;
	}

}
.page-node-15 .webform-client-form {
	@media(min-width:740px){
		float:left;
		width:50%;
	}
}


.front #section-headerban{
	display: none;
}
#block-block-23 iframe{
	height: 200px;
	@media(min-width: 740px){
		width: 100%;

    height: 500px;
	}
}

.webform-client-form .form-item {
	margin-top:0px;
}
input[type=text], input[type=email] {
	width:100%;
}
.node-type-produit .field-name-field-photo {
	float:left;
	width:18%;
	margin-right: 2%;
    margin-bottom: 2%;
	img{
		width: 100%;
		height: auto;
	}
}



.node-type-produit .field-name-body{
	float:left;
	width:80%;
}
.views_slideshow_cycle_main, .views-slideshow-cycle-main-frame-row, .views-slideshow-cycle-main-frame-row-item, .views-slideshow-cycle-main-frame {
	width: 100% !important;
}
.views-slideshow-cycle-main-frame-row  {
/*
	height: 350px;
	width: 100% !important;
	max-width:1230px;
	*/
}
.slideshow_container {
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;

	@media(min-width: 740px){
	//	background-size: 1230px 350px;
	}
	img{
		visibility: hidden;
		width: 100%;
		max-height: 350px;
	}


	@media (min-width: 740px){
		/*
		position:absolute;
		top:0px;
		width: 100% !important;
		max-width:1230px;
		height: 350px;*/

	}
}
/*********************************************/


/*********************************************/
/* EDIT */
.views-field-edit-node {
	display: block;
clear: both;
}
.views-field-edit-node a {
	font-size: 0.95em;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
text-decoration: none;
color: #00622b;
margin-right: 20px;
padding: 5px 10px 5px 10px;
background-color: #00622b;
color: white;
-webkit-border-radius: 40px;
-moz-border-radius: 40x;
border-radius: 40px;
}
/*********************************************/



/*********************************************/
/* TABS*/
.tabs {
	margin-bottom:15px;
	margin-top:10px;
	overflow: hidden;
}
#section-content ul.primary li a  {
	background: #00622b;
	color:#eaeaea;
	font-family:arial;
	border:0px;
	padding: 10px;
	display: inline-block;
}
#section-content ul.primary li a:hover, #section-content ul.primary li.active a {
	background:#161b1d;
	border:0px;
}
#section-content ul.primary {
	border-bottom:1px solid #00622b;
	padding: 0 0 0 0;
	margin:0px;
	margin-top:10px;
}

/*********************************************/



#block-views-nos-produits-cedra-block h2{
	line-height: 38px;
	font-size: 2.4em;
}
/*
div.field-name-field-galerie-photos a{
	margin: 15px 15px 0 0;
	padding: 0;
	float: left;
	display: block;
}*/
.field-name-field-galerie-photos{
	a{
		float: left;
		width: 49%;
		display: inline-block;
		margin-right: 1%;
		margin-bottom: 1%;
		&:nth-child(2n){
			margin-right: 0;
		}
		img{width: 100%;height: auto;}
		@media(min-width: 740px){
			width: 23%;
			&:nth-child(2n){
				margin-right: 1%;
			}
			&:nth-child(4n){
				margin-right: 0%;
			}
		}
	}

}



.field-item .field-collection-item-field-paragraphe{
@media(min-width: 740px){
		height:400px
}
		@media(min-width: 980px){
				height:334px;
		}
}

sup{
vertical-align: super;
font-size: smaller;

}


#region-footer{
	text-align:center;
}

#block-footer-sitemap-footer-sitemap{
	width:100%;
	text-align:center;
	margin: 0 auto;
}

#block-footer-sitemap-footer-sitemap .block-inner{
	width: 100%;
	@media (min-width: 740px){
		width: 798px;
		margin: 0 auto;
	}

	ul li{
		width: 100%;
		margin-bottom: 10px;
		@media (min-width: 740px){
			width: auto;
			margin: 0;
		}
	}

}


/* Municipalites et entreprenefur generaux */


#block-block-25{

	margin-top: 20px;
	float: right;
	.content a{
	    text-decoration: none;
	    background: rgba(255, 196, 64, 0.26);
	    border-radius: 10px;
	    padding: 10px;
	    display: block;
	    font-weight: bold;
	    /* vertical-align: middle; */
	    line-height: 28px;
	    border: 1px solid rgba(31, 31, 31, 0.12);
	    color: #00622b;
	    &:hover{
		    text-decoration: underline;
	    }
		img{
			margin-right: 4px;
			width: 64px;
		}
	}
}



/* Google MAP*/

#map {
    width: 1230px;
    height: 350px;
}

#livraison{
	text-align: center;
}

.img-responsive{
	max-width: 100%;
}



#region-bloc1,
#region-bloc2,
#region-bloc3{
	p{
		color: black;
	}
	.block-title{
		font-size: 2em;
		color: black;
	}
}


.avertissement{color:red;}

#form-calculateur{
   text-align: center;
   display:flex;
   flex-direction: column;
   margin-top:30px;
}
#form-calculateur > div{
   margin-bottom: 20px;
}
#form-calculateur label{
   font-weight: bold;
}

.colorbox-inline{
   //color:red !important;
   font-weight: bold !important;
 }
